$white: #ffffff;
$dirty-white: #F6F4F4;
$green : #0A5640;
$green-dark: #124734;
$green-light: #C3D600;
$yellow: #ffce00;
$black: #000000;
$green-gradient-diagonal: linear-gradient(135deg, #006241, #124734);
$green-gradient: linear-gradient(to right, #006241, #124734);
$font-primary: proxima-nova, sans-serif;
$font-secondary: proxima-nova-condensed, sans-serif;