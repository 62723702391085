
@use 'vars' as *;

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 10px;
    font-family: $font-primary;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    border:none;
    color: #808080;
}
input[type=submit]{
    line-height: 1;
}
input[type=radio]{
    margin:0;
    margin-right: 5px;
    width: 21px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    &::after {
        margin: auto;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        position: absolute;
        inset:0;
        background-color: transparent;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $green;
    }
    &:checked:after {
        margin: auto;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        position: absolute;
        inset:0;
        background-color: $green;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 3px solid $white;
        transform: scale(.9);
        outline: 2px solid $green;
    }
    & + label{
        padding-left:5px;
    }
}
select{
    color: #808080;
    text-align: left;
    font-family: $font-primary;
    font-size: 16px;
    box-sizing: border-box;
    border:none;
    padding-left: 10px;
    height: 40px;
}
textarea {
    min-height: 80px;
}

form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

label, .label{
    font-size: 16px;

    &.small{
        font-size: 12px;
    }
}
.form-wrapper{
    background-color: #F1F1F1;
    padding:20px 0;
    .column{
        margin-bottom: 1rem;
    }
    .two-col{
        align-items: end;
    }
    input[type=submit]{
        width: auto;
        min-width: 124px;
    }
    .with-spacer{
        padding-bottom: 2rem;
    }
    .with-spacer-sm{
        padding-bottom: 1rem;
    }
    .no-wrap{
        flex-wrap: nowrap;
    }
    input, textarea{     
        border:1px solid #707070
    }
    #form-result{
        font-size: clamp(18px,4vw, 32px);
        color:$green;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        text-align: center;
    }
}
.t-form{
    font-size: clamp(18px, 4.5vw, 24px);
}

