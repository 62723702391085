@use 'vars' as *;

#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #000;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
}
.slide-menu {
	background: $green;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:#fff;
	background:$green-dark;
	margin:0;
	font-weight:bold;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:$dirty-white;
    a{
        color:$black;
    }
    .nav-arrow{
        filter: invert(1);
    }
}
.slide-menu ul ul ul{
	background:$white;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid #030b16;
	padding: 18px;
	text-decoration:none;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: $yellow;
    color: $black;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 59px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
@media only screen and (max-width: 960px) {
    #mobile-menu {
        display:block;
    }
    .header-menu,
    .main-menu > ul{
        display:none;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }
    .alert{
        margin-right:70px;
    }
}