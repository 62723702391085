@use 'vars' as *;

/*============ footer ============*/
footer{
    background:$green-gradient-diagonal;
    color:#fff;
    font-family: $font-secondary;
    .copyright{
        margin-top: 1rem!important;
    }
    .logo-wrapper{
        margin:auto;
        width: 100%;
        max-width: 1530px;
        margin-bottom: -25px;
        a.logo{
            margin-left:20px;
            transform: translatey(-50%);
            // margin-bottom: -20%;
        }
        @media screen and (max-width:1200px) {
            display: flex;
            justify-content: center;
            text-align: center;
            a.logo{
                margin: auto;
                display: flex;
                justify-content: center;
            }
        }
    }
 
    .footer-bottom {
        display: flex;
        margin:auto;
        max-width: 1400px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:6rem 20px;
        padding-top: 0;
        .site-column{
            .two-col{
                grid-template-columns:1fr;
            }
        }
        .menu-column{
            padding-left: 2rem;
            font-size: clamp(18px, 4.5vw, 24px);
            display: flex;
            flex-direction: column;
            & > ul{
                display: flex;
                justify-content: space-between;
                list-style: none;
                margin:0;
                padding:0;
                margin-top: 27px;
                margin-bottom: 2rem;
                li{
                    padding:0 1rem;
                    text-align: left;
                    a:hover{
                        color:$yellow;
                    }
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    li {
                    	padding-left: 0.75em !important;
                    	font-size: 0.75em;
                    }
                }
            }
            
        }
        .fine-print{
            margin-top: auto;
            font-size: clamp(14px, 3vw, 18px);
   
            p{
                width: 100%;
                padding:0;
                margin:0;
            }
            .acknowledgement {
                font-style: italic;
            }
        }
        .footer-contact{
            display: flex;
            flex-direction: column;
            .site-name{
                padding-left:0;
                font-size: clamp(14px, 3vw, 18px);
                padding-top: 0;
                text-align: left;
                .text-light{
                    font-weight: 400;
                }
                p{
                    margin-top: 0;
                }
            }
            .site-links {
                margin-top: 2rem;
                margin-bottom: 0;
                a.site-link{
                    justify-content: left;
                    margin-left: 0;
                }
            }
            .contact-details{
                margin-top: 0;
                display: flex;
                flex-direction: column;
                font-size: 18px;
            }
        }
      
        .bykeo{
            text-align: left;
            a{
                color:$white;
                font-size: clamp(14px, 3vw, 18px);
            }
        }
        @media screen and (max-width:1200px) {
            flex-direction: column;
            padding-bottom: 3rem;
            .site-column, .site-name {
                text-align: center!important;
            }
            .the-nationals{
                margin-left: auto;
            }
            .site-links{
                justify-content: center;
                a.site-link{
                    justify-content: center;
                    margin:.5rem;
                } 
            }
            .menu-column {
                padding:0;
                &> ul{
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin-top: 0;
                    li{
                        padding:0; 
                    }
                    margin-bottom: 2rem;
                }
                .fine-print, .bykeo{
                    text-align: center;
                }
            }
            .contact-details{
                margin-bottom: 0;
            }
        }
    }  
} 
.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding:20px 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    display:block;
    padding-left:30px;
    position:relative;
    line-height:30px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}