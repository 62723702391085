@use 'vars' as *;

.sub-templates{
    section{
        padding:4rem 6rem;
        @media screen and (max-width:992px) {
            padding:4rem 3rem; 
        }
        @media screen and (max-width:768px) {
            padding:4rem 1.5rem; 
        }
    }
    .page-title{
        font-family: $font-secondary;
        font-weight: 500;
        font-size: clamp(22px, 5vw, 48px);
        color: $white;
    }
    
}
.subtemplate-latest_news{
    background-color: $dirty-white;

    .page-title{
        color: $green;
        margin-right: 5rem;
    }
}
.subtemplate-map_projects{
    background-color: $green-dark;
    hr{
        max-width: 436px;
    }
    div[data-edit]{
        h1{
            font-size: clamp(24px, 7vw, 72px);
            font-family: $font-secondary;
            font-weight: 300;
        }
        .two-col{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li{
              padding-right: 4rem;
          }
        }
    }
    .map-block{
        display: flex;
        flex-direction: row;
        align-items: center;
        .content-section{
            flex-shrink: 1;
        }
        .map-section{
            // flex-shrink: 0;
            position: relative;
            .map-svg{
                pointer-events: none;
                object-fit: contain;
            }
            .dot-wrap{
                position: absolute;
                inset:0;
                .project_marker{
                    height: 12px;
                    width: 12px;
                    display: inline-flex;
                    border: 1px solid $white;
                    background-color:  $yellow;
                    margin-top: -7px;
                    margin-left: -7px;
                    position: absolute;
                    border-radius:50%;
                    text-decoration: none;
                    transition: border .3s ease-in-out, outline .3s ease-in-out;
                    align-items: center;
                    span{
                        color: $yellow;
                        font-size: clamp(14px, .9vw, 21px);
                        display: block;
                        transform: translate(15px, -1px);
                        font-family: $font-secondary;
                        font-weight: 500;
                        
                        @media screen and (max-width:768px) and (min-width:577px)
                        {
                            display: none;
                        }
                   
                        @media screen and (max-width:400px) {
                            display: none;
                        }
                    }
                    &:hover{
                        border:1px solid $yellow;
                        outline: 2px solid $yellow;
                        span{
                            display: block;
                        }
                    }
                }
            }
        }

        @media screen and (max-width:576px) {
            flex-direction: column;
            padding:0;
            .content-section{
                padding-bottom: 2rem;
            }
        }
    }
}
.subtemplate-latest_project{
    background-color: $green;
    .view-more{
        color:$yellow;
    }
    .page-title{
        margin-right: 5rem;
    }
    .card{
        .title{
            color:$yellow;
        }
        .date, .summary{
            color:$white;
        }
       
    }
  
}
.subtemplate-about_kevin{
    font-size: clamp(18px, 4.5vw, 24px);
    .view-more{
        font-size: clamp(17px, 4vw, 20px);
    }
    h1{
        font-size: clamp(22px, 5vw, 48px);
        font-family: $font-secondary;
        font-weight: 500;
        margin-bottom: 0;
    }
    p{
        margin-top: 0;
        font-weight: 300;
    }
    .two-col{
        li{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
        }
        img{
            margin:auto;
        }
    }
}
.subtemplate-subscription{
    background-color: $yellow;
    padding:4rem 4rem;
    
    .content{
        font-size: clamp(18px, 5vw, 24px);
        font-family: $font-secondary;
        color:$green;
        .title{
            font-weight: 500;
            font-size: clamp(22px, 5vw, 48px);
            color:$black;
            margin-top:0;
        }
    }
  
    .inline{
        align-items: stretch;
        margin-bottom: .5rem;
    }
    .column{
        justify-content: flex-end;
        margin-left:1rem;
        flex-grow: 1;
    }
    button[type=submit]{
        width:auto;
        margin-bottom: 18px;
        border: 1px solid $green;
    }
    .recaptcha-notice{
        margin-left:1rem;
        margin-top:1rem;
    }
    .email-col{
        flex-basis: 70%;
    }
    .form-result-wrap{
        font-size: clamp(18px,4vw, 32px);
        color:$green;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }
    input{
        border: 1px solid #707070;
    }
    @media screen and (max-width:992px) {
        padding:4rem 3rem; 
    }
    @media screen and (max-width:768px) {
        padding:4rem 1.5rem; 
        .two-col{
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;
        }
        .inline > .column:first-child{
            margin-left: 0;
        }
    }
    @media screen and (max-width: 576px){
        .contentwrap{
            padding:0;
        }
        .inline {
            flex-direction: column;
            .column{
                margin:0;
            }
        }
    }
}