@use 'vars' as *;

.t-home{
    
   .page-content{
        font-size: clamp(18px, 4.5vw, 26px);
   }
   #content{
       padding:0 2rem;
   }
   .page-graphic-wrapper{
        position: relative;
        .subtitle{
            color:$white;
            // background-color: rgba(0, 0, 0, 0.27);
            font-family: proxima-nova-condensed, sans-serif;
            position: absolute;
            inset: 0;
            font-size: clamp(24px, 7vw, 72px);
            display: flex;
            align-items: center;
            pointer-events: none;
            
        }
       
   }
}