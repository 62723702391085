@use 'vars' as *;

.t-news{
    
   .page-title{
       font-size: clamp(24px, 7vw , 72px);
       font-weight: 300;
       font-family: $font-secondary;
       text-align: center;
       color: $green;
   }
   .page-wrapper{
    padding:4rem 6rem;
    @media screen and (max-width:992px) {
        padding:4rem 3rem; 
    }
    @media screen and (max-width:768px) {
        padding:4rem 1.5rem; 
    }
    }
    .pager{
        margin-top: 4rem;
    }
    .three-col{
        grid-gap: 6rem 0.75rem;
    }
}