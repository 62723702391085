@use 'vars' as *;

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:12px;
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
.inline{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    &.center{
        align-items: center;
    }
}
.column{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.padded-wrapper{
    padding:0 20px;
}

a[href='']{
    pointer-events: none;
}
div[data-edit]{
    h1{
        font-size: clamp(22px, 5vw, 48px);
    }
    a{
        color:$green;
    }
}
.three-col{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:2rem .75rem;

    /* Default children style*/
    & > * {
        grid-column: auto / span 2;
    }

    /* Style if it only contains one child*/
    & > *:only-child {
        grid-column: 2 / span 4;
    }

    /* Style if it only contains two children*/
    & > *:first-child:nth-last-child(2){
        grid-column: 2  / span 2;
    }

    @media screen and (max-width:992px) {
        & > *,
        & > *:first-child:nth-last-child(2){
            grid-column: auto / span 3;
        }
    }
    @media screen and (max-width:768px) {
        & > *,
        & > *:first-child:nth-last-child(2),
        & > *:only-child{
            grid-column: 1 / -1;
        }
    }
}
.card{
    a{
        text-decoration: none;
        // color:initial;

        .overlay{
            position: relative;
            img{
                width: 100%;
                aspect-ratio: 4/3;
                object-fit: cover;
            }
            &::after{
                content:"Read More";
                opacity: 0;
                transition: opacity .3s ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                inset:0;
                color: $white;
                background-color: rgba(0, 0, 0, .2);
                font-size: clamp(18px,3vw,24px);
                font-weight: 700;
            }
        }
        &:hover{
            .overlay::after{
                opacity: 1;    
            }
        }
    }
   
   
    .title{
        font-family: $font-secondary;
        font-weight: 700;
        font-size: clamp(18px, 5vw, 24px);
        color: $green;
        margin-bottom: 5px;
    }
    .date{
        font-weight: 700;
        font-size:16px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .summary{
        font-size: clamp(17px, 4vw, 20px);
    }
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    min-width: 135px;
    width: 135px;
    height: 135px!important;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    h1{
        margin:0;
    }
}
.pager{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .button{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}
@import '~lazyframe/src/scss/lazyframe';
@import "menus";
@import "forms";
@import "site_links";
@import "header";
@import "mobile_menu";
@import "footer";
@import "index";
@import "page";
@import "contact";
@import "block";
@import "news";
@import "projects";
@import "faq";

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}
@import "helpers";
@import "print";