@use 'vars' as *;

/*============ header ============*/
header.top {
    font-family: $font-secondary;
    font-size:16px;
    @media screen and (max-width:576px) {
        padding-bottom: 1.5rem;
       
    }
    @media screen and (max-width:300px) {
       
        padding-bottom: 2rem;
    }
}

.header-bar-top {
    color:$yellow;
    background: $green-dark;
    display: flex; 
    justify-content: flex-end;
    padding:0 4rem;
    
    a{
        margin:1rem;
    }
    
    @media screen and (max-width:576px) {
        padding-top: 1rem;
        padding-right: 3.5rem;
        padding-left: 1rem;
        flex-direction: column;
        .site-links{
            align-self: flex-end;
        }
    }
    @media screen and (max-width:300px) {
        .site-links, .the-nationals{
            display: none;
        }
        padding-top:60px;
    }
}



.header-bar-bottom {
    background: $green-gradient;
    color:#fff;
    position:relative;
    display: flex;
    align-items: flex-end;
    padding: 0 6rem;
    justify-content: space-between;
    // flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
        padding:0 2rem;
      
    }
    @media screen and (max-width: 960px) {
        padding:0 1.5rem;
      
    }
    @media screen and (max-width:576px) {
        padding:0;
        flex-wrap: wrap-reverse;
    }
    .two-col{
        display: flex;
        flex-wrap: wrap;
    }
    .logo-wrapper{
        display:flex;
        align-items: flex-end;
        
        a.logo{
            border:4px solid #fff;
            margin-bottom: -2rem;
            margin-top:5px;
           
        }
        
        // @media screen and (max-width:576px) {
        //     a.logo{
        //         display: none;
        //     }
        
        // }
        @media screen and (max-width:576px) {
            margin-right: 1rem;
            margin-left: auto;
        }
      
    }
    
}
.the-nationals{
    width: 238px;
    align-self: center;
    margin-right: auto;
}
.site-name{
    padding:1.5rem;
    padding-right:0;
    font-size: 16px;
    h1, h2{
        white-space: nowrap;
    }
    h1, h2{
        margin:0;
        padding:0;
        line-height: 1
    }
    h1{
        line-height: 30px;
        font-size: clamp(22px, 6vw, 40px);
    }
    h2{
        font-size: clamp(18px, 5vw, 24px);
    }
    p{
        margin:0;
        margin-top: 7px;
        padding:0;
    }
    li{
        padding:0;
    }
    @media screen and (max-width:576px) {
        h1, h2{
            white-space: normal;
        }
    }
    
}
.site-links{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    a.site-link{
        margin:.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 20px;
            height: 20px!important;
            object-fit: contain;
            transition: transform .3s ease-in-out;
            }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    
}
.main-menu {
    margin-top: auto;
    font-size: clamp(18px, 4.5vw, 24px);
    width: 100%;
    font-weight: 300;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > ul{
        justify-content: flex-end;
        // padding-top: 2rem;
        display: flex;
        // width:100%;
        margin-left: auto;
        align-self: stretch;
        position: relative;
        & > li{
            margin-left:1rem;
            text-align: center;
            position: relative;
           
            a{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 2rem;
                .title{
                    text-align: left;
                }
            }
        }
    }
    ul {
        text-align:justify;
        line-height:0;
      
      
    }
}

.main-menu li {
    padding: 5px;
    line-height:1;
}
.main-menu li.depth-0 {
    vertical-align:middle;
}

.main-menu a {
    padding:15px 0;

    text-decoration: none;
    :hover{
        color:$yellow;
    }
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;

    background:#f6f6f6;
    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;
    box-shadow: 0 0 4px #444;
}
.main-menu li:hover > ul {
    display:flex;
    flex-direction: column;
    white-space: nowrap;
    padding-right: 1rem;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";

    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
   
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
}
.main-menu .depth-1 .title {
    font-size: clamp(14px, 3vw, 18px);
}
.main-menu .depth-1 a {
    width: 160px;
    margin: .5rem;
    padding:0;
}