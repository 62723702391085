@use 'vars' as *;

/*============ sub page============*/
.page-content {
    padding:5px;
}
.page-graphic {
    display: block;
    width: 100%;
    position: relative;

    img {
        width: 100%;
        aspect-ratio: 23 / 10;
        object-fit: cover;
    }
    
  
    .subtitle{
        background-color: rgba(0,0,0,.27);
        font-family: $font-secondary;
        position: absolute;
        inset:0;
        font-size: clamp(24px, 7vw, 72px);
        display: flex;
        align-items: center;
        color: $yellow;
    }
}
.page-graphic-wrapper{
    padding:2.5rem;
    .page-graphic{
        .subtitle{
            font-size: clamp(20px, 6vw, 63px);
            color: $yellow;
        }
    }
    @media screen and (max-width:576px) {
        padding: 1.5rem;
       
    }
    @media screen and (max-width:300px) {
       
        padding:5px;
    }
}
.page-subtitle{
    font-size: clamp(20px, 5vw, 48px);
    font-family: $font-secondary;
    font-weight: 500;
    color:$green;
}
.t-page{
    .page-body{
        font-size: clamp(17px, 4vw, 20px);
    }
}

.embed-container {

    position: relative;
    max-width: 1600px;
    margin: auto;
    .lazyframe{
        background-color: transparent;
        background-size: contain;
        aspect-ratio: 16/9;
    } 
  }
   
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
.vp-center{
    align-items: stretch;
}