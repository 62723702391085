@use 'vars' as *;

.t-project{
    .page-title{
        font-size: clamp(24px, 7vw , 72px);
        font-weight: 300;
        font-family: $font-secondary;
        text-align: center;
        color: $green;
    }
    .page-wrapper{
        padding:4rem 6rem;
        @media screen and (max-width:992px) {
            padding:4rem 3rem; 
        }
        @media screen and (max-width:768px) {
            padding:4rem 1.5rem; 
        }
    }
    .ongoing-wrapper{
        background-color: #F6F4F4;
        padding:4rem 6rem;
        & > .title{
            margin-top: 0;
            margin-bottom: 4rem;
            font-weight: 500;
            font-size: clamp(22px, 5vw, 48px);
            color: $green;
            font-family: $font-secondary;
        }
        @media screen and (max-width:992px) {
            padding:4rem 3rem; 
        }
        @media screen and (max-width:768px) {
            padding:4rem 1.5rem; 
        }
    }
    .completed-wrapper{
        & > .title{
            margin-top: 0;
            margin-bottom: 4rem;
            font-weight: 500;
            font-size: clamp(22px, 5vw, 48px);
            color: $green;
            font-family: $font-secondary;
        }
    }
    .pager{
        margin-top: 4rem;
    }
    .inline{
        .location-tag{
            background-color: $green-light;
            font-size: clamp(14px, 3vw, 20px);
            color: $green;
            padding:10px;
            text-decoration: none;
            margin-right: 5px;
            margin-bottom: 10px;
            &:last-child{
                margin-right: 0;
            }
            &.active, &:hover{
                background-color: $green;
                color:$green-light;
            }
        }
        @media screen and (max-width:576px) {
        display: none;
        }
    }
     .three-col{
        grid-gap: 6rem 0.75rem;
    }
   
}
.search-wrap{
    background-color: #F2F2F2;
    padding:0 1rem;
    padding-top: 1rem;
    & > form{
        display: flex;
        flex-wrap: wrap;
        justify-content: stretch;
        input, select{
            width: auto;
            margin-bottom:1rem;
            margin-right: 1rem;
            flex-grow: 1;
            margin-bottom:1rem;
            height: 35px;
            padding-left:10px;
            color:#808080;
            option{
                padding-left: 0;
            }
        }
        #search-field{
            flex-grow: 3;
        }
    }
    .button{
        width: auto;
        margin-bottom:1rem;
    }
    @media screen and (max-width:576px) {
        & > form{
            flex-direction: column;
        }
    }
} 