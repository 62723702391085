@use 'vars' as *;

.t-faq{
    font-size: clamp(18px, 2vw, 24px);
    .faq-main-wrapper{
        padding-bottom: 6rem;
    }
    .faq-card{

        .faq-title{
            cursor: pointer;
            color: $green;
            font-size: clamp(18px, 2vw, 24px);
            font-weight: 300;
            &.bold{
                font-weight: 700;
            }
        }
        .faq-content{
            font-size: clamp(14px, 1.5vw, 18px);
            transition: max-height .3s ease-in-out, margin-bottom .3s ease-in-out;
            overflow: hidden;
            color: #707070;
            margin-bottom: 2rem;
            .sub-faq-card:last-child{
                margin-bottom: 4rem;
            }
        }

        &.fold{
            & > .faq-content{
                max-height: 0px!important;
                margin-bottom: 0;
            }
        }
    
    }
    hr{
        margin:0;
        border-color: $green;
    }
} 