
/*============ Contact ============*/

.map-wrap {

    width: 100%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}

.contact-details p {
    margin: 5px 0;
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
    max-height: 500px;
    min-height: 400px;
    height: 30vw;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}
.t-contact{
    font-size: 18px;
    .form-wrapper{
        padding-bottom:4rem;
    }
    .site-links-wrapper{
        margin-bottom: 6rem;
        margin-top: 0;
        padding-top: 0;
        .site-links{
            margin-left: 1rem;
            a.site-link{
                margin:0;
                img{
                    height: 16px!important;
                }
            }
        } 
      
    }
}